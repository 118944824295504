import {ActionType, GlobalState} from "./type";
import {Action} from "redux";
import {configureStore} from "@reduxjs/toolkit";

const defaultState: GlobalState = {
    modules: [],
    skills: [],
    userInfo: undefined
}

export function Index(state: GlobalState = defaultState, action: Action & GlobalState): GlobalState {
    switch (action.type) {
        case ActionType.SAVE_MODULES:
            return {...state, modules: action.modules}
        case  ActionType.SAVE_USER_INFO:
            return {...state, userInfo: action.userInfo}
        case ActionType.SAVE_USER_SKILLS:
            return {...state, skills: action.skills}
        default:
            return defaultState
    }
}

const reducer = configureStore({reducer: Index})
export default reducer;
