import React, {Fragment, useCallback, useEffect, useState} from "react";
// @ts-ignore
import ReactCursorPosition from "react-cursor-position";
import {Element} from "react-scroll";
import Header from "../components/layouts/Header";
import About from "../components/sections/About";
import Blogs from "../components/sections/Blogs";
import Contact from "../components/sections/Contact";
import Experiences from "../components/sections/Experiences";
import HeroSection from "../components/sections/HeroSection";
import Skills from "../components/sections/Skills";
import Project from "../components/sections/Project";
import {useDispatch, useSelector} from "react-redux";
import {saveModules, saveSkills, saveUserInfo} from "../store/action";
import {getModules, getUserInfo, getUserSkills} from "../network/api";
import {GlobalState} from "../store/type";
import {Module, ModuleType} from "../entity/User";

function HomePage() {
    const dispatch = useDispatch();
    const [toggleMenu, setToggleMenu] = useState(false);
    const modules = useSelector<GlobalState, Module[]>(state => state.modules)
    useEffect(() => {
        getUserInfo(1).then(res => dispatch(saveUserInfo(res)))
        getModules(1).then(res => dispatch(saveModules(res)))
        getUserSkills(1).then(res => dispatch(saveSkills(res)));
    }, [dispatch])

    const headerToggle = (e: any) => {
        e.preventDefault();
        setToggleMenu(!toggleMenu);
    };

    document.addEventListener("click", function (e) {
        // @ts-ignore
        if (e.target.closest(".content")) {
            setToggleMenu(false);
        }
    });
    const getSections = useCallback(() => {
        return <Fragment>
            {modules.map(module => {
                let name: string
                let com: React.ReactNode
                switch (module.type) {
                    case ModuleType.home:
                        name = 'section-home';
                        break;
                    case ModuleType.aboutMe:
                        name = 'section-about';
                        com = module.state === 0 && <About title={module.title}/>;
                        break;
                    case ModuleType.skill:
                        name = 'section-services';
                        com = module.state === 0 && <Skills title={module.title}/>
                        break;
                    case ModuleType.experience:
                        name = 'section-experiences';
                        com = module.state === 0 && <Experiences title={module.title}/>
                        break;
                    case ModuleType.project:
                        name = 'section-works';
                        com = module.state === 0 && <Project title={module.title}/>
                        break;
                    case ModuleType.blog:
                        name = 'section-blogs';
                        com = module.state === 0 && <Blogs title={module.title}/>
                        break;
                    case ModuleType.contact:
                        name = 'section-contact';
                        com = module.state === 0 && <Contact title={module.title}/>
                        break;

                }
                switch (module.type === 0) {
                    case true:
                        return <Element name="section-home" key={module.id}>
                            <ReactCursorPosition>
                                <HeroSection/>
                            </ReactCursorPosition>
                        </Element>
                    case false:
                        return <Element name={name} key={module.id}>
                            {com}
                        </Element>
                }
                return undefined
            })}
        </Fragment>
    }, [modules])

    return (
        <Fragment>
            <Header
                logoSource="/images/logo.svg"
                toggleMenu={toggleMenu}
                headerToggler={headerToggle} light={undefined}/>
            <main className={toggleMenu ? "content open" : "content"}>
                {getSections()}
                {/*<Element name="section-home">*/}
                {/*    <ReactCursorPosition>*/}
                {/*        <HeroSection/>*/}
                {/*    </ReactCursorPosition>*/}
                {/*</Element>*/}
                {/*<Element name="section-about">*/}
                {/*    <About/>*/}
                {/*</Element>*/}
                {/*<Element name="section-services">*/}
                {/*    <Services/>*/}
                {/*</Element>*/}
                {/*<Element name="section-experiences">*/}
                {/*    <Experiences/>*/}
                {/*</Element>*/}
                {/*<Element name="section-works">*/}
                {/*    <Works/>*/}
                {/*</Element>*/}
                {/*<Element name="section-blogs">*/}
                {/*    <Blogs/>*/}
                {/*</Element>*/}
                {/*<Element name="section-pricing">*/}
                {/*    <Pricing/>*/}
                {/*</Element>*/}
                {/*<Element name="section-testimoninal">*/}
                {/*    <Testimonials/>*/}
                {/*</Element>*/}
                {/*<Element name="section-brandlogos">*/}
                {/*    <BrandLogos/>*/}
                {/*</Element>*/}

                {/*<Element name="section-contact">*/}
                {/*    <Contact/>*/}
                {/*</Element>*/}
                <div className="spacer" data-height="96"></div>
            </main>
        </Fragment>
    );
}

export default HomePage;
