import {Module, Skill, UserInfo} from "../entity/User";

/**
 * action的唯一标识
 */
export enum ActionType {
    SAVE_MODULES = "SAVE_MODULES",
    SAVE_USER_INFO = "SAVE_USER_INFO",
    SAVE_USER_SKILLS = "SAVE_USER_SKILLS",
}

/**
 * action返回的给store的结果,有type以及指定GlobalState的部分字段(泛型表示)
 */
export type  ActionResult<T extends keyof GlobalState> = {
    type: ActionType,
} & Pick<GlobalState, T>


/**
 * store的状态类型
 */
export type GlobalState = {
    modules: Module[],
    skills: Skill[],
    userInfo?: UserInfo,
}
