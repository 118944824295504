import React, {useState, useEffect} from "react";
import ScrollAnimation from "react-animate-on-scroll";
import PageTitle from "../elements/PageTitle";
import Portfolio from "../elements/Portfolio";

const filters = [
    {
        id: 1,
        text: "Everything",
    },
    {
        id: 2,
        text: "creative",
    },
    {
        id: 3,
        text: "art",
    },
    {
        id: 4,
        text: "design",
    },
    {
        id: 5,
        text: "branding",
    },
];

const allData = [
    {
        id: 1,
        title: "Dicom Browser",
        category: "art",
        image: "images/app-dicom.png",
        type: "url",
        // popupLink: ["images/works/1.svg"],
        url: 'https://www.dicombrowser.com',
    },
    {
        id: 2,
        title: "教育圈App",
        category: "art",
        image: "images/app-jyq.png",
        type: "image",
        // popupLink: ["images/works/1.svg"],
        popupLink: [
            "images/app-jyq-1.png",
            'images/app-jyq-2.png',
            'images/app-jyq-3.png',
            'images/app-jyq-4.png',
        ],

    },


    {
        id: 3,
        title: "人教智能教辅",
        category: "creative",
        image: "images/app-znjf.png",
        type: "video",
        popupLink: ["https://jeffreywei.oss-cn-beijing.aliyuncs.com/resume/video/zhinengjiaofu.mp4"],
    },

    {
        id: 4,
        title: "新华财经App",
        category: "branding",
        image: "images/app-xhcj.png",
        type: "image",
        popupLink: [
            "images/app-xhcj-1.jpg",
            'images/app-xhcj-2.jpg',
            'images/app-xhcj-3.jpg',
            'images/app-xhcj-4.jpg',
            'images/app-xhcj-5.jpg',
        ],
    },

];
type Props = {
    title: string
}

function Project(props: Props) {
    const [getAllItems] = useState(allData);
    const [dataVisibleCount, setDataVisibleCount] = useState(6);
    const [dataIncrement] = useState(3);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);
    const [noMorePost, setNoMorePost] = useState(false);

    useEffect(() => {
        setActiveFilter(filters[0].text.toLowerCase());
        // @ts-ignore
        setVisibleItems(getAllItems.filter((item) => item.id <= 6));
    }, [getAllItems]);

    const handleChange = (e: any) => {
        e.preventDefault();
        setActiveFilter(e.target.textContent.toLowerCase());
        let tempData;
        if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
            tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
        } else {
            tempData = getAllItems.filter(
                (data) =>
                    data.category === e.target.textContent.toLowerCase() &&
                    data.id <= dataVisibleCount
            );
        }
        // @ts-ignore
        setVisibleItems(tempData);
    };

    const handleLoadmore = (e: any) => {
        e.preventDefault();
        let tempCount = dataVisibleCount + dataIncrement;
        if (dataVisibleCount > getAllItems.length) {
            setNoMorePost(true);
        } else {
            setDataVisibleCount(tempCount);
            if (activeFilter === filters[0].text.toLowerCase()) {
                console.log("they are same");
                // @ts-ignore
                setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
            } else {
                // @ts-ignore
                setVisibleItems(
                    // @ts-ignore
                    getAllItems.filter(
                        (data) => data.category === activeFilter && data.id <= tempCount
                    )
                );
            }
        }
    };

    // @ts-ignore
    // @ts-ignore
    return (
        <section id="works">
            <div className="container">
                <PageTitle title={props.title}/>
                {/* Start Portfolio Filters */}
                <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}
                >
                    <ul className="portfolio-filter list-inline">
                        {filters.map((filter) => (
                            <li className="list-inline-item" key={filter.id}>
                                <button
                                    onClick={handleChange}
                                    className={
                                        filter.text.toLowerCase() === activeFilter
                                            ? "text-capitalize current"
                                            : "text-capitalize"
                                    }
                                >
                                    {filter.text}
                                </button>
                            </li>
                        ))}
                    </ul>
                </ScrollAnimation>
                {/* End Portfolio Filters */}

                {/* Start Portfolio Items */}
                <div className="row portfolio-wrapper">
                    {visibleItems.map((item) => (
                        // @ts-ignore
                        <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
                            <Portfolio portfolio={item}/>
                        </div>
                    ))}
                </div>
                {/* End Portfolio Items */}

                <div className="load-more text-center mt-4">
                    <button
                        className="btn btn-default"
                        onClick={handleLoadmore}
                        // @ts-ignore
                        disabled={noMorePost ? "disabled" : null}
                    >
                        {noMorePost ? (
                            "No more items"
                        ) : (
                            <span>
                <i className="fas fa-spinner"></i> Load more
              </span>
                        )}
                    </button>
                </div>
            </div>
        </section>
    );
}

export default Project;
