import React from "react";

function SkillProgress({skill, isVisible}) {
    const {title, level, color} = skill;
    const winWidth = window.innerWidth;
    const progressQuery = () => {
        if (winWidth && winWidth > 767) {
            return (
                <div
                    className="progress-bar data-background"
                    style={
                        isVisible
                            ? {
                                width: `${level}%`,
                                background: color,
                            }
                            : {width: 0, background: color}
                    }
                ></div>
            );
        }
        return (
            <div
                className="progress-bar data-background"
                style={{
                    width: `${level}%`,
                    background: color,
                }}
            ></div>
        );
    };
    return (
        <div className="skill-item">
            <div className="skill-info clearfix">
                <h4 className="float-left mb-3 mt-0">{title}</h4>
                <span className="float-right">{level}%</span>
            </div>
            <div className="progress">{progressQuery()}</div>
        </div>
    );
}

export default SkillProgress;
