import React from "react";
import {Link} from "react-router-dom";
import Blog from "../elements/Blog";
import PageTitle from "../elements/PageTitle";

const allBlogs = [
    {
        id: 1,
        title: "5 Best App Development Tool for Your Project",
        image: "images/blog/1.svg",
        fileSource: "../../blogs/best-app-development-tool.md",
        date: "09 February, 2020",
        author: "Bolby",
        category: "Reviews",
    },
    {
        id: 2,
        title: "Common Misconceptions About Payment",
        image: "images/blog/2.svg",
        fileSource: "../../blogs/common-misconceptions-about-payment.md",
        date: "07 February, 2020",
        author: "Bolby",
        category: "Tutorial",
    },
    {
        id: 3,
        title: "3 Things To Know About Startup Business",
        image: "images/blog/3.svg",
        fileSource: "../../blogs/things-to-know-about-startup-business.md",
        date: "06 February, 2020",
        author: "Bolby",
        category: "Business",
    },
];
type Props = {
    title: string
}

function Blogs(props: Props) {
    return (
        <section id="blog">
            <div className="container">
                <PageTitle title={props.title}/>
                <div className="row blog-wrapper">
                    {allBlogs.map((blogItem) => (
                        <div className="col-md-4" key={blogItem.id}>
                            <Blog blogData={blogItem}/>
                        </div>
                    ))}
                </div>
                <div className="text-center">
                    <div className="spacer" data-height="30"></div>
                    <Link to="/blogs" className="btn btn-default">
                        Show all blogs
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Blogs;
