import React, {Fragment} from "react";
import ScrollAnimation from "react-animate-on-scroll";

type Props = { title?: string }

function PageTitle(props: Props) {
    const {title} = props
    return <Fragment>
        <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
        >
            <h2 className="section-title">{title}</h2>
        </ScrollAnimation>
        <div className="spacer" data-height="60"></div>
    </Fragment>
}

export default PageTitle;
