import React from "react";
import {Link} from "react-scroll";
import PageTitle from "../elements/PageTitle";
import Service from "../elements/Service";
import {useSelector} from "react-redux";
import {GlobalState} from "../../store/type";
import {Skill} from "../../entity/User";

const servicesData = [
    {
        id: 1,
        icon: "images/service-1.svg",
        title: "UI/UX design",
        content:
            "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
        color: "#6C6CE5",
        contentColor: "light",
    },
    {
        id: 2,
        icon: "images/service-2.svg",
        title: "Web Development",
        content:
            "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
        color: "#F9D74C",
        contentColor: "dark",
    },
    {
        id: 3,
        icon: "images/service-3.svg",
        title: "Photography",
        content:
            "Lorem ipsum dolor sit amet consectetuer adipiscing elit aenean commodo ligula eget.",
        color: "#F97B8B",
        contentColor: "light",
    },
];
type Props = { title: string }

function Skills(props: Props) {
    const skills = useSelector<GlobalState, Skill[]>(state => state.skills)
    return (
        <section id="services">
            <div className="container">
                <PageTitle title={props.title}/>
                <div className="row fix-spacing">
                    {skills.map((skill) => (
                        <div className="col-md-4" key={skill.id}>
                            <Service service={{
                                id: skill.id,
                                icon: skill.url,
                                content: skill.introduction,
                                color: skill.color,
                                contentColor: 'light',
                            }}/>
                        </div>
                    ))}
                </div>
                <div className="mt-5 text-center">
                    <p className="mb-0">
                        Looking for a custom job?{" "}
                        <Link
                            className="colorpink pointer"
                            to="section-contact"
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            Click here
                        </Link>{" "}
                        to contact me! 👋
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Skills;
