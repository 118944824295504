import React, {useMemo} from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import PageTitle from "../elements/PageTitle";
import SkillProgress from "../elements/SkillProgress";
import {useSelector} from "react-redux";
import {GlobalState} from "../../store/type";
import {Skill, UserInfo} from "../../entity/User";

const aboutContent = {
    name: "Bolby",
    avatarImage: "/images/avatar-2.svg",
    content:
        "I am Bolby Doe, web developer from London, United Kingdom. I have rich experience in web site design and building and customization, also I am good at WordPress.",
};

const counterData = [
    {
        id: 1,
        title: "Projects completed",
        count: 198,
        icon: "icon-fire",
    },
    {
        id: 2,
        title: "Cup of coffee",
        count: 5670,
        icon: "icon-cup",
    },
    {
        id: 3,
        title: "Satisfied clients",
        count: 427,
        icon: "icon-people",
    },
    {
        id: 4,
        title: "Nominees winner",
        count: 35,
        icon: "icon-badge",
    },
];
type Props = {
    title: string
}

function About(props: Props) {
    const userInfo = useSelector<GlobalState, UserInfo | undefined>(state => state.userInfo)
    const skills = useSelector<GlobalState, Skill[]>(state => state.skills)
    const introduction = useMemo(() => userInfo?.introduction ?? "", [userInfo])
    return (
        <section id="about">
            <div className="container">
                <PageTitle title={props.title}/>
                <div className="row">
                    <div className="col-md-3">
                        <div className="text-center text-md-left">
                            <img src={aboutContent.avatarImage} alt={aboutContent.name}/>
                        </div>
                        <div className="spacer d-md-none d-lg-none" data-height="30"></div>
                    </div>

                    <div className="col-md-9 triangle-left-md triangle-top-sm">
                        <div className="rounded bg-white shadow-dark padding-30">
                            <div className="row">
                                <div className="col-md-6">
                                    <p>{introduction}</p>
                                    {/*<div className="mt-3">*/}
                                    {/*    <a href="!#" className="btn btn-default">*/}
                                    {/*        Download CV*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                    <div
                                        className="spacer d-md-none d-lg-none"
                                        data-height="30"
                                    ></div>
                                </div>
                                <div className="col-md-6">
                                    {skills.map((skill) => (
                                        <TrackVisibility
                                            once
                                            key={skill.id}
                                            className="progress-wrapper"
                                        >
                                            <SkillProgress skill={skill} isVisible={undefined}/>
                                        </TrackVisibility>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer" data-height="70"></div>
                {/*数据类的暂时隐藏*/}
                {/*<div className="row fix-spacing">*/}
                {/*    {counterData.map((counter) => (*/}
                {/*        <div key={counter.id} className="col-md-3 col-sm-6">*/}
                {/*            <TrackVisibility once>*/}
                {/*                <Counter counterItem={counter} isVisible={undefined}/>*/}
                {/*            </TrackVisibility>*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {/*</div>*/}
            </div>
        </section>
    );
}

export default About;
