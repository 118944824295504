import React, {Fragment, useCallback} from "react";
import {Link} from "react-scroll";
import Logo from "../elements/Logo";
import {useSelector} from "react-redux";
import {GlobalState} from "../../store/type";
import {Module, ModuleType} from "../../entity/User";

type Props = {
    light: any, logoSource: any, toggleMenu: any, headerToggler: any
}

function Header(props: Props) {
    const {light, logoSource, toggleMenu, headerToggler} = props
    const modules = useSelector<GlobalState, Module[]>(state => state.modules)
    const handleClasses = () => {
        let classes = "desktop-header-1 d-flex align-items-start flex-column";
        if (light & toggleMenu) {
            classes += " light open";
        } else if (toggleMenu) {
            classes += " open";
        } else if (light) {
            classes += " light";
        }
        return classes;
    };
    const handleMobileClasses = () => {
        let classes = "mobile-header-1";
        if (light & toggleMenu) {
            classes += " light open";
        } else if (toggleMenu) {
            classes += " open";
        } else if (light) {
            classes += " light";
        }
        return classes;
    };
    const getTabs = useCallback(() => {
        return <Fragment>
            {modules.map(module => {
                let toPath: string
                let iConClassName: string
                switch (module.type) {
                    case ModuleType.home:
                        toPath = 'section-home';
                        iConClassName = 'icon-home';
                        break;
                    case ModuleType.aboutMe:
                        toPath = 'section-about';
                        iConClassName = 'icon-user-following';
                        break;
                    case ModuleType.skill:
                        toPath = 'section-services';
                        iConClassName = 'icon-briefcase';
                        break;
                    case ModuleType.experience:
                        toPath = 'section-experiences';
                        iConClassName = 'icon-graduation';
                        break;
                    case ModuleType.project:
                        toPath = 'section-works';
                        iConClassName = 'icon-layers';
                        break;
                    case ModuleType.blog:
                        toPath = 'section-blogs';
                        iConClassName = 'icon-note';
                        break;
                    case ModuleType.contact:
                        toPath = 'section-contact';
                        iConClassName = 'icon-bubbles';
                        break;

                }
                return <li hidden={module.state !== 0} key={module.id}>
                    <Link
                        activeClass="active"
                        to={toPath}
                        spy={true}
                        smooth={true}
                        duration={500}
                    >
                        <i className={iConClassName}></i>{module.title}
                    </Link>
                </li>
            })}
        </Fragment>
    }, [modules])
    return <Fragment>
        <header className={handleMobileClasses()}>
            <div className="container">
                <div className="menu-icon d-inline-flex mr-4">
                    <button onClick={headerToggler}>
                        <span></span>
                    </button>
                </div>
                <Logo logoSource={logoSource} bootstrapNav={undefined}/>
            </div>
        </header>
        <header className={handleClasses()}>
            <Logo logoSource={logoSource} bootstrapNav={undefined}/>
            <nav>
                <ul className="vertical-menu scrollspy">
                    {getTabs()}
                </ul>
            </nav>

            <div className="footer">
                {/*<span className="copyright">*/}
                {/*  &copy; {new Date().getFullYear()} Bolby Template.*/}
                {/*</span>*/}
            </div>
        </header>
    </Fragment>
}

export default Header;
