import {Education, Experience, Module, ModuleType, Skill, UserInfo} from "../entity/User";


/**
 * 获取用户信息
 * @param id 用户id
 */
export async function getUserInfo(id: number): Promise<UserInfo> {
    return {
        id: 0,
        introduction: "大家好,我是Jeffrey,80后,毕业于华北水利水电大学,从事前端和iOS开发已近10年,前端主要采用React和Vue,并能使用uniapp开发小程序,iOS则主要采用Swift语言。 目前正在寻找合适的工作岗位,如果有意向,可以联系我,当然,也欢迎和我一起探讨一些技术上的问题。",
        nickName: "Jeffrey Wei",
        signature: "专注于前端和iOS开发~"
    }
    // return axios.get("api/user/userInfo", {params: {id}})
}

/**
 * 获取用户技能信息
 * @param uid 用户id
 */
export async function getUserSkills(uid: number): Promise<Skill[]> {
    return [
        {
            color: "#FFD15C",
            id: 0,
            introduction: "熟练掌握Swift、OC语言,熟练掌握RxSwift以及各常用第三方库",
            level: 90,
            title: "iOS开发",
            url: "images/logo-xcode.jpg"
        },
        {
            color: "#FF4C60",
            id: 0,
            introduction: "熟练掌握React,使用ts以及函数式组件进行项目开发",
            level: 90,
            title: "React",
            url: "images/logo-react.png"
        },
        {
            color: "#6C6CE5",
            id: 0,
            introduction: "熟练掌握Vue进行项目开发,并能使用uni-app开发微信小程序",
            level: 90,
            title: "Vue",
            url: "images/logo-vue.jpg"
        },
    ]
    // return axios.get("/api/user/getUserSkills", {params: {uid}})
}

/**
 * 获取模块信息
 * @param uid 用户id
 */
export async function getModules(uid: number): Promise<Module[]> {
    return [
        {id: 0, state: 0, title: "首页", type: ModuleType.home, uid: 1},
        {id: 0, state: 0, title: "关于我", type: ModuleType.aboutMe, uid: 1},
        {id: 0, state: 0, title: "专业技能", type: ModuleType.skill, uid: 1},
        {id: 0, state: 0, title: "工作经历", type: ModuleType.experience, uid: 1},
        {id: 0, state: 0, title: "主要项目", type: ModuleType.project, uid: 1},
        // {id: 0, state: 0, title: "博客", type: ModuleType.blog, uid: 1},
        {id: 0, state: 0, title: "联系我", type: ModuleType.contact, uid: 1},
    ]
    // return axios.get("/api/module/getModules", {params: {uid}})
}

export async function getExperience(uid: number): Promise<Experience[]> {
    return [
        {content: "", id: 0, title: "", years: ""},
        {content: "", id: 0, title: "", years: ""},
        {content: "", id: 0, title: "", years: ""},
    ]
    // return axios.get("/api/module/getModules", {params: {uid}})
}
