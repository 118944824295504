export type UserInfo = {
    id: number
    nickName: string
    signature: string
    introduction: string
}

export enum ModuleType {
    home,
    aboutMe,
    skill,
    experience,
    project,
    blog,
    contact,
}

export  type Module = {
    id: number,
    uid: number,
    state: 0 | 1
    title: string,
    type: ModuleType
}

export  type Skill = {
    id: number,
    url: string,
    title: string,
    color: string,
    introduction: string,
    level: number
}
export  type Experience = {
    id: number
    title: string,
    years: string,
    content: string
}

export type Education = {
    id: number
    title: string,
    years: string,
    content: string
}
