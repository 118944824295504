import React, {useState} from "react";
import ScrollAnimation from "react-animate-on-scroll";
import PageTitle from "../elements/PageTitle";

type Props = { title: string }

function Contact(props: Props) {
    const [formdata, setFormdata] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });

    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");

    const submitHandler = (event: any) => {
        event.preventDefault();
        if (!formdata.name) {
            setError(true);
            setMessage("Name is required");
        } else if (!formdata.email) {
            setError(true);
            setMessage("Email is required");
        } else if (!formdata.subject) {
            setError(true);
            setMessage("Subject is required");
        } else if (!formdata.message) {
            setError(true);
            setMessage("Message is required");
        } else {
            setError(false);
            setMessage("You message has been sent!!!");
        }
    };

    const handleChange = (event: any) => {
        setFormdata({
            ...formdata,
            [event.currentTarget.name]: event.currentTarget.value,
        });
    };

    const handleAlerts = () => {
        if (error && message) {
            return <div className="alert alert-danger mt-4">{message}</div>;
        } else if (!error && message) {
            return <div className="alert alert-success mt-4">{message}</div>;
        } else {
            return null;
        }
    };

    return (
        <section id="contact">
            <div className="container">
                <PageTitle title={props.title}/>

                <div className="row">
                    <div className="col-md-4">
                        <div className="contact-info">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >
                                <h3>您通过旁边的表单来联系我</h3>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >
                                <p>
                                    如果您不喜欢用表格的形式,也可以给我发送{" "}
                                    <a href="mailto:name@example.com">email</a>. 👋
                                </p>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <div className="col-md-8">
                        <form
                            id="contact-form"
                            className="contact-form mt-6"
                            onSubmit={submitHandler}
                        >
                            <div className="row">
                                <div className="column col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="name"
                                            id="InputName"
                                            placeholder="您的姓名"
                                            onChange={handleChange}
                                            value={formdata.name}
                                        />
                                    </div>
                                </div>

                                <div className="column col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="InputEmail"
                                            name="email"
                                            placeholder="您的Email地址"
                                            onChange={handleChange}
                                            value={formdata.email}
                                        />
                                    </div>
                                </div>

                                <div className="column col-md-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="InputSubject"
                                            name="subject"
                                            placeholder="话题"
                                            onChange={handleChange}
                                            value={formdata.subject}
                                        />
                                    </div>
                                </div>

                                <div className="column col-md-12">
                                    <div className="form-group">
                    <textarea
                        name="message"
                        id="InputMessage"
                        className="form-control"
                        // @ts-ignore
                        rows="5"
                        placeholder="请输入内容"
                        onChange={handleChange}
                        value={formdata.message}
                    ></textarea>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                name="submit"
                                id="submit"
                                value="Submit"
                                className="btn btn-default"
                            >
                                发送给我~
                            </button>
                        </form>
                        {handleAlerts()}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
