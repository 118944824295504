import React from "react";
import PageTitle from "../elements/PageTitle";
import Timeline from "../elements/Timeline";

const educationData = [
    {
        id: 1,
        title: "Acamedic Degree",
        years: "2019 - Present",
        content:
            "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
    },
    {
        id: 2,
        title: "Bachelor’s Degree",
        years: "2017 - 2013",
        content:
            "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
    },
    {
        id: 3,
        title: "Honours Degree",
        years: "2013 - 2009",
        content:
            "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
    },
];

const experienceData = [
    {
        id: 1,
        company: "景杉医疗科技有限公司",
        title: "技术经理",
        years: "2023 - 2024",
        content:
            "负责公司影像系统的架构设计和发布工作\n" +
            "独立开发影像核心功能,并和管理端进行实时交互\n" +
            "进行影像系统pc端的开发",
    },
    {
        id: 1,
        company: "中教云控股集团有限公司",
        title: "技术经理",
        years: "2020 - 2023",
        content:
            "负责iOS原生应用的架构设计、开发以及发布等工作\n" +
            "对接产品、梳理需求,保证项目迭代的稳定\n" +
            "负责部分前端产品的整体设计和开发工作\n" +
            "调研新技术,以满足公司产品和业务的需求",
    },
    {
        id: 2,
        company: "北京易诚互动网络技术股份有限公司",
        title: "iOS高级开发工程师",
        years: "2017.7 - 2019.5",
        content:
            "负责App股票模块相关开发工作\n" +
            "负责K线图的算法定制和K线图以及各指标图的原生开发工作",
    },
    {
        id: 3,
        company: '上海信而富企业管理有限公司',
        title: "移动端负责人",
        years: "2014.8 - 2019.5",
        content:
            "负责App的iOS端整体架构设计和开发。\n" +
            "review团队成员代码,保证代码质量。\n" +
            "负责代码的版本控制,处理好tag和branches的安排。\n" +
            "负责和其它部门的沟通和协调以及需求的讨论等,保证App逻辑的合理性。\n" +
            "负责App的打包和上架,处理上架事务。\n" +
            "负责一部分前端Vue的开发和设计工作\n" +
            "追踪前沿技术的发展，结合实际应用需求进行技术的改进与创新",
    },
];
type Props = { title: string }

function Experiences(props: Props) {
    return (
        <section id="experience">
            <div className="container">
                <PageTitle title={props.title}/>
                <div className="row">
                    {/*<div className="col-md-6">*/}
                    {/*    <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">*/}
                    {/*        {educationData.map((education) => (*/}
                    {/*            <Timeline key={education.id} education={education}/>*/}
                    {/*        ))}*/}
                    {/*        <span className="line"></span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-md-6">
                        <div className="spacer d-md-none d-lg-none" data-height="30"></div>
                        <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
                            {experienceData.map((experience) => (
                                <Timeline key={experience.id} education={experience}/>
                            ))}
                            <span className="line"></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Experiences;
