import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

function Timeline({education}) {
    const {years, title, content,company} = education;
    return (
        <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
        >
            <div className="timeline-container">
                <div className="content">
                    <span className="time">{years}</span>
                    <h3 className="title">{company}</h3>
                    <h6 className="title">{title}</h6>
                    <p style={{whiteSpace: 'pre-line'}}>{content}</p>
                </div>
            </div>
        </ScrollAnimation>
    );
}

export default Timeline;
