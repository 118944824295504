import {ActionResult, ActionType} from "./type";
import {Module, Skill, UserInfo} from "../entity/User";

export function saveModules(modules: Module[]): ActionResult<"modules"> {
    return {
        type: ActionType.SAVE_MODULES,
        modules
    }
}

export function saveUserInfo(userInfo: UserInfo): ActionResult<"userInfo"> {
    return {
        type: ActionType.SAVE_USER_INFO,
        userInfo
    }
}

export function saveSkills(skills: Skill[]): ActionResult<"skills"> {
    return {
        type: ActionType.SAVE_USER_SKILLS,
        skills
    }
}
